import React, { Fragment, useEffect } from "react"
import classNames from "classnames"

import Hero from "layout/Hero"
import Container from "layout/Container"
import HomeMediaContainer from "./HomeMediaContainer"

import useHomeImages from "../../hooks/useHomeImages"
import { animate } from "../../../../services/animations"
import howToParticipateGiotrif from "../../utils/howToParticipateGiotrif.json"

const HomeHowToParticipate = () => {
  const data = useHomeImages()

  useEffect(() => {
    animate({
      y: 50,
      type: "from",
      isScrollTrigger: true,
      target: ".fade-to-upper-programs",
      animateOn: ".fade-to-upper-programs",
    })
  }, [])

  return (
    <Hero size="small">
      <Container isCentered desktop={10} fullhd={8}>
        <div className="mb-3">
          <h3 className="has-text-centered has-text-primary">
            How can I participate in the program?
          </h3>
        </div>
        <div className="columns is-centered mb-3">
          {howToParticipateGiotrif.map(item => (
            <Fragment>
              <HomeMediaContainer
                title={item?.title}
                description={item?.description}
                image={data[item?.imageName]?.childImageSharp?.fixed}
                animationClass={"fade-to-upper-programs"}
              />
            </Fragment>
          ))}
        </div>
      </Container>
    </Hero>
  )
}

export default HomeHowToParticipate
