import React, { useEffect, Fragment } from "react"
import classNames from "classnames"
import { Link } from "gatsby"

import Container from "layout/Container"
import Hero from "layout/Hero"

import styles from "../../utils/staticPages.modules.scss"
import { animate } from "../../../../services/animations"

import financialAssessmentRequirements from "../../utils/financialAssessmentRequirements.json"

const HomeCard = () => {
  useEffect(() => {
    animate({
      y: 50,
      type: "from",
      isScrollTrigger: true,
      target: ".fade-to-upper-corporate",
      animateOn: ".animate-corporate-trigger",
    })
    animate({
      y: 50,
      type: "from",
      isScrollTrigger: true,
      target: ".fade-to-upper-careers",
      animateOn: ".animate-careers-trigger",
    })
  }, [])

  return (
    <Hero>
      <div class="card">
        <div class="card-content">
          <div className="columns">
            <div className="column px-2 px-0-mobile">
              <h3 className="title is-size-4 mt-0 has-text-secondary fade-to-upper-corporate">
                For existing Afatinib (Giotrif) patients:
              </h3>
              <hr
                className={classNames(
                  "fade-to-upper-corporate",
                  styles["card__divider"]
                )}
              />
              <p className="fade-to-upper-corporate">
                Please provide consent for MedGrocer to request your Afatinib
                (Giotrif) order history from Globo Asiatico Enterprises, Inc. or
                Onco Care Pharma Corporation.
              </p>
              <section className="animate-corporate-trigger"></section>
            </div>
            <div className="column px-2 px-0-mobile">
              <h1 className="title is-size-4 mt-0 has-text-secondary fade-to-upper-careers">
                For patients newly prescribed with Afatinib (Giotrif):
              </h1>
              <hr
                className={classNames(
                  "fade-to-upper-careers",
                  styles["card__divider"]
                )}
              />
              <p className="fade-to-upper-careers pb-1">
                Fill out the financial assessment form and submit your proof of
                income and information on your expenses. Proof of income
                documentation includes:
              </p>
              <article className="message fade-to-upper-careers">
                <div className={classNames("message-body collapsible")}>
                  <section className="content ">
                    {financialAssessmentRequirements && (
                      <Fragment>
                        <ul className="ml-1-mobile">
                          {financialAssessmentRequirements.map(value => (
                            <li
                              className="is-size-6 pb-0"
                              dangerouslySetInnerHTML={{ __html: value }}
                            ></li>
                          ))}
                        </ul>
                      </Fragment>
                    )}
                  </section>
                </div>
              </article>
              <section className="animate-careers-trigger"></section>
            </div>
          </div>
        </div>
      </div>
    </Hero>
  )
}

export default HomeCard
