import React, { Fragment } from "react"
import classNames from "classnames"

import HomeCard from "./HomeCard"

import { Link } from "gatsby"
import Container from "layout/Container"
import financialAssessmentRequirements from "../../utils/financialAssessmentRequirements.json"

const HomeProgramEligibility = () => {
  return (
    <Container isCentered desktop={10} fullhd={8}>
      <h3 className="my-3 has-text-centered has-text-primary">
        Who is eligible to participate in the program?
      </h3>
      <h5 className="subtitle has-text-centered mb-5">
        You can enroll in the program if you are prescribed by our partner
        doctor any of the medicines covered in the program.
      </h5>
      <h3 className="my-3 has-text-centered has-text-primary">
        How do I apply for medicine assistance?
      </h3>
      <p className="subtitle has-text-centered">
        Patients who are already enrolled in the program may apply for medicine
        assistance.
      </p>

      <HomeCard />

      <p className="subtitle">
        Once you have submitted your complete requirements, our team will verify
        your application to determine the medicine assistance that will be
        granted to you. You will receive an SMS indicating the results of your
        application within 2-5 working days. Once you receive the results of
        your application, you may claim your medicine assistance through the
        website.
      </p>
      <p className="subtitle">
        Have another question? Get answers at our{" "}
        <Link to="/giotrif/help-center" className="has-text-weight-bold">
          Help Center
        </Link>
        .
      </p>
    </Container>
  )
}

export default HomeProgramEligibility
