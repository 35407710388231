import React, { Fragment, useEffect, useContext } from "react"
import { navigate } from "gatsby"

import SEO from "layout/SEO"
import Layout from "layout/Layout"

import HomeBanner from "./HomeBanner"
import HomeHowToParticipate from "./HomeHowToParticipate"
import HomeProgramEligibility from "./HomeProgramEligibility"

import { AppContext } from "../../../../context/AppContext"
import { RESET_STATE } from "../../../../context/AppReducer"
import { GATSBY_ENABLED_SITES } from "gatsby-env-variables"

const Home = () => {
  const { dispatch, state } = useContext(AppContext)

  useEffect(() => {
    if (!GATSBY_ENABLED_SITES.includes("Giotrif")) navigate("/")
    dispatch({
      type: RESET_STATE,
    })
  })

  return (
    <Layout>
      <SEO title="Home" />
      <Fragment>
        <HomeBanner />
        <HomeHowToParticipate />
        <HomeProgramEligibility />
      </Fragment>
    </Layout>
  )
}

export default Home
